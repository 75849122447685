<template>
  <div>
      <center><iframe src="https://drive.google.com/file/d/1_nD-iiUjAxfqdgqjjgm9agxPaFAm3_hk/preview" allow="autoplay" style="margin-left: 25px; margin-right: 25px; min-width: 85%; min-height: 85vh;"></iframe></center>
  </div>
</template>

<script>
export default {
    name: "ResumeComponent"

}
</script>

<style>

</style>